<template>
    <article
        v-for="feature in FeaturedProductsList"
        :key="feature.key"
        class="pa-6 section-border-y"
    >
        <v-row no-gutters>
            <v-col>
                <h2
                    class="text-center text-uppercase font-size-5 letter-spacing-5 font-weight-bold"
                >
                    {{ feature.title }}
                </h2>
            </v-col>
        </v-row>
        <ContainerContent>
            <v-row>
                <v-col
                    v-for="(item, index) in feature.items"
                    :key="index"
                    cols="12"
                    md="4"
                >
                    <v-card
                        :to="item.path"
                        elevation="4"
                        :aria-label="item.title"
                    >
                        <v-img
                            :src="item.imageUrl ?? getResourceCdnUrl(item.image)"
                            :aspect-ratio="itemAspectRatio"
                            :alt="feature.title"
                            :class="item.imgClass ?? ''"
                            :position="item.imgPosition ?? void(0)"
                            :cover="item.imgCover"
                        ></v-img>
                    </v-card>
                    <NuxtLink
                        :to="item.path"
                        class="no-style-link d-block text-center text-uppercase font-size-6 letter-spacing-5 font-weight-bold mt-4"
                        :title="item.altText"
                        :aria-label="item.title"
                    >
                        {{ item.title }}
                    </NuxtLink>
                </v-col>
            </v-row>
        </ContainerContent>
    </article>
</template>

<script lang="ts" setup>
    import { FeaturedProductsList } from '~/models';
    import { useCdnResource } from '~/composables';
    import ContainerContent from '~/components/ContainerContent/ContainerContent.vue';

    defineProps({
        itemAspectRatio: {
            type: Number,
            default: 1
        }
    });

    const useCdnResourceObj = useCdnResource();
    const { getResourceCdnUrl } = useCdnResourceObj;
</script>

<style lang="scss"></style>
